<template>
  <div
    class="bg-white dark:bg-jevi-dark w-full px-5 flex flex-col justify-center items-center h-screen"
  >
    <div class="w-full h-full overflow-y-auto">
      <div class="my-5">
        <iframe
          width="100%"
          height="300"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1061738029&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        ></iframe>
        <div
          style="
            font-size: 10px;
            color: #cccccc;
            line-break: anywhere;
            word-break: normal;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-family: Interstate, Lucida Grande, Lucida Sans Unicode,
              Lucida Sans, Garuda, Verdana, Tahoma, sans-serif;
            font-weight: 100;
          "
        >
          <a
            href="https://soundcloud.com/badbunny15"
            title="Bad-Bunny"
            target="_blank"
            style="color: #cccccc; text-decoration: none"
            >Bad-Bunny</a
          >
          ·
          <a
            href="https://soundcloud.com/badbunny15/bad-bunny-yonaguni"
            title="Bad Bunny - Yonaguni"
            target="_blank"
            style="color: #cccccc; text-decoration: none"
            >Bad Bunny - Yonaguni</a
          >
        </div>
      </div>

      <div class="my-5">
        <iframe
          width="100%"
          height="300"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1313963293&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        ></iframe>
        <div
          style="
            font-size: 10px;
            color: #cccccc;
            line-break: anywhere;
            word-break: normal;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-family: Interstate, Lucida Grande, Lucida Sans Unicode,
              Lucida Sans, Garuda, Verdana, Tahoma, sans-serif;
            font-weight: 100;
          "
        >
          <a
            href="https://soundcloud.com/idla"
            title="Best Playlists Ever"
            target="_blank"
            style="color: #cccccc; text-decoration: none"
            >Best Playlists Ever</a
          >
          ·
          <a
            href="https://soundcloud.com/idla/sets/gone-long-gone-1"
            title="Gone, Long Gone"
            target="_blank"
            style="color: #cccccc; text-decoration: none"
            >Gone, Long Gone</a
          >
        </div>
      </div>

      <div class="my-5">
        <iframe
          width="100%"
          height="300"
          scrolling="no"
          frameborder="no"
          allow="autoplay"
          src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/293102011&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
        ></iframe>
        <div
          style="
            font-size: 10px;
            color: #cccccc;
            line-break: anywhere;
            word-break: normal;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-family: Interstate, Lucida Grande, Lucida Sans Unicode,
              Lucida Sans, Garuda, Verdana, Tahoma, sans-serif;
            font-weight: 100;
          "
        >
          <a
            href="https://soundcloud.com/kickthehabit"
            title="Kick The Habit"
            target="_blank"
            style="color: #cccccc; text-decoration: none"
            >Kick The Habit</a
          >
          ·
          <a
            href="https://soundcloud.com/kickthehabit/sets/kick-the-habit-owners-full-album"
            title="Kick The Habit - Owners [FULL ALBUM]"
            target="_blank"
            style="color: #cccccc; text-decoration: none"
            >Kick The Habit - Owners [FULL ALBUM]</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require("axios")
const notify = () => import("@/utils/notify.js")

export default {
  components: {},
  data() {
    return {
      loading: true
    }
  },
  computed: {
    theme() {
      return this.$store.getters["getTheme"]
    },
    loggedUser() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {},
  async created() {
    // Set global theme
    const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)")
    mediaQueryList.addEventListener("change", (event) => {
      this.$store.dispatch("setTheme", event.matches ? "dark" : "light")
    })
    this.$store.dispatch("setTheme", mediaQueryList.matches ? "dark" : "light")
  },
  watch: {}
}
</script>
